//start tax-exemption page layout
.tax a {
  color: #206209 !important;
}

.track-link {
  color: #206209 !important;
}

.tax .c-app {
  padding-bottom: 5rem !important;
}

.tax .c-sidebar a:hover {
  color: #206209;
}

.tax .c-wrapper {
  margin-left: 0px !important;
}

.tax-header {
  background: linear-gradient(to bottom, #228541 5%, #237f03 100%);
  margin-bottom: 0rem !important;
}

.tax .c-sidebar {
  background-color: rgb(235, 237, 239) !important;
  color: rgb(0, 0, 0, 0.8) !important;
}

.tax .c-sidebar a,
.tax .c-sidebar .c-sidebar-nav-title,
.tax .c-sidebar svg,
.tax .c-sidebar .c-sidebar-nav-dropdown-toggle::after {
  color: rgb(0, 0, 0, 0.8) !important;
}

.tax .c-sidebar .c-sidebar-nav-item .c-active {
  background-color: rgb(235, 237, 239) !important;
  color: #206209 !important;
}

.tax .c-sidebar .c-sidebar-nav-item {
  border-bottom: 1px solid #a7afb7 !important;
  font-size: medium;
  font-weight: 600;
}

.tax .c-sidebar .c-sidebar-nav-item a:hover {
  background-color: rgb(235, 237, 239) !important;
  color: #206209 !important;
}

.tax .c-sidebar {
  padding: right 20px, i !important;
}

.tax .c-header .c-subheader {
  margin-top: 0px !important ;
}

.tax .c-header {
  border-bottom: none !important ;
  background-color: rgb(235, 237, 239) !important;
}

.tax .c-footer {
  margin-top: 1.5rem;
}

//end tax-exemption layout page

//dashboard
.taxCount {
  padding-bottom: 1.25rem !important;
}
//end dashboard

//start tax-exemption-request page
.formLink .nav-link {
  padding: 0.5rem 0rem !important;
}

.formLink .nav-justified .nav-item {
  padding: 0rem 0.2rem;
}

.formLink .progress-bar {
  background-color: #228541 !important;
  border-color: #206209 !important;
}

.formLink .progress {
  background-color: #c2cbd3 !important;
  border-color: #a7afb7 !important;
}

.contentForm {
  color: black;
  padding-right: 2rem;
}

.contentForm h2,
.contentForm h3,
.contentForm h6 {
  color: black;
}

.TrackingSystem .trackingTaxButton .trackingSearchIcon {
  margin: 0rem 0 !important;
}

.sadIcon {
  width: 10rem !important;
  height: 10rem !important;
}

.progressTrack .progress {
  background-color: rgb(217, 217, 217) !important;
}

.progressTrack .bg-success {
  background-color: #228541 !important;
}

.taxExemptionProgress,
.taxExemptionDetails,
.taxExemptionShipping {
  border: 3px solid !important;
  border-color: #ced2d8 !important;
  background-color: transparent !important;
}

.taxExemptionProgress .card-header,
.taxExemptionDetails .card-header,
.taxExemptionShipping .card-header {
  background-color: transparent !important;
}

.required-text {
  color: red;
}

.errorInfoTax {
  color: red !important;
}
//end tax-exemption-request page

//start changePassword admin-staff
.changePassword {
  display: flex;
  min-height: 80vh;
  align-items: center;
}
.changePasswordForm,
.changePassword {
  padding: 20px 0px;
}

.changePassword .condition-changePassword {
  font-size: 18px;
}
//end change Password admin-staff

//start donorAnalysis admin

.topThreeDonor .top {
  text-align: center !important;
}

.top h1 {
  font-size: 3rem;
}

.topThreeDonor .topDetails {
  padding-top: 15px;
}

.topThreeDonor .small {
  font-size: 50% !important;
  text-align: center;
}

.donorRanking .topDonor {
  padding-top: 30px;
}

.topDonor h5 {
  padding-bottom: 15px;
  padding-top: 15px;
}

.textDescriptionDonorRanking {
  padding: 20px;
}

.donorTitle {
  padding-top: 15px !important;
}

.donorRanking .table-responsive {
  padding-left: 1.8rem;
  padding-right: 1.8rem;
}

.topThreeDonor {
  padding-right: 2rem;
  padding-left: 2rem;
}

.topThreeDonor .top .first {
  background-color: rgba(240, 208, 116, 100);
  color: #996506;
}

.topThreeDonor .top .second {
  background-color: rgba(189, 186, 186, 100);
  color: #616161;
}

.topThreeDonor .top .third {
  background-color: rgba(207, 190, 165, 100);
  color: #6a3805;
}

.noDataIcon {
  width: 3rem !important;
  height: 3rem !important;
  margin-bottom: 1rem;
}

.donorStateChart canvas {
  width: 90% !important;
  height: 90% !important;
}

.donorStateCard {
  margin-right: 23px !important;
  margin-left: 23px !important;
}
//end donorAnalysis admin

//export Calendar
.calendar__head,
.calendar__day--start,
.calendar__day--end {
  background: #636f83 !important;
}

.calendar__day--range {
  background: #bcc2cb !important;
}

.calendar__day:hover {
  background: #d4d8de !important;
}

.calendar__day--today {
  background: #e3e6ea !important;
}
//end Export Calendar

//taxexemption table page -admin page
.taxExemptionTable .masterChecked {
  margin-top: -0.5rem !important;
}
//end taxexemption table page -admin page

@include media-breakpoint-up(sm) {
  .topThreeDonor .small {
    font-size: 50% !important;
    text-align: center;
  }

  //start donorAnalysis admin
  .topThreeDonor {
    padding-right: 1.7rem !important;
    padding-left: 1.7rem !important;
  }
  //end donorAnalysis admin
}
@include media-breakpoint-up(md) {
  //start tax-exemption page layout
  .tax .c-sidebar-fixed {
    position: sticky !important;
    top: 0px;
  }

  .tax .c-wrapper {
    padding-top: 20px;
  }

  .tax .c-app {
    padding-top: 4rem !important;
  }
  //end tax-exemption page layout

  //start donorAnalysis admin
  .topThreeDonor {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important;
  }
  //end donorAnalysis admin
}

@include media-breakpoint-up(xl) {
  //start tax-exemption page layout
  .tax .c-app {
    padding-left: 5rem !important;
  }

  .tax .c-wrapper {
    margin-left: 80px !important;
  }
  //end tax-exemption page layout

  //start donorAnalysis admin
  .topThreeDonor .small {
    font-size: 40% !important;
  }
  .topThreeDonor {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }

  .topThreeDonor .col-xl-3 {
    flex: 0 0 28% !important;
    max-width: 28% !important;
  }
  //end donorAnalysis admin
}

@include media-breakpoint-up(xxl) {
  //start donorAnalysis admin
  .topThreeDonor .small {
    font-size: 56% !important;
  }
  //end donorAnalysis admin
}

.delete {
  color: red;
}

.error-text {
  color: #d93737;
}
